import React from "react";
import {
    MainContainer,
    MainDiv,
    SplitLeft,
    SplitRight,
    InfoBottomText,
    InfoTopText,
    TextHalf,
    ImgHalf,
} from "./SplitContent.styles";

import BrainFig from "../../common/img/PrecisionImage.png";
import { Row, Col } from "antd";
import { Fade, Flip } from "react-reveal";

export const SplitContent = ({ title, body, img, invertOrder = false }) => {
    const col1Order = invertOrder ? 1 : 2;
    const col2Order = invertOrder ? 2 : 1;
    const imgFadeLeft = invertOrder ? true : false;
    const imgFadeRight = invertOrder ? false : true;

    return (
        <MainDiv>
            <MainContainer>
                <Row justify={"center"} align={"middle"} gutter={16}>
                    <Col
                        xs={{ order: 1, span: 24 }}
                        sm={{ order: col2Order, span: 18 }}
                    >
                        <TextHalf>
                            <Fade down duration={1800} opposite={true}>
                                <h1>{title}</h1>
                            </Fade>
                            <Fade up distance="100px" duration={1000}>
                                <InfoBottomText>{body}</InfoBottomText>
                            </Fade>
                        </TextHalf>
                    </Col>
                    <Col
                        xs={{ order: 2, span: 24 }}
                        sm={{ order: col1Order, span: 6 }}
                    >
                        <Fade
                            left={imgFadeLeft}
                            right={imgFadeRight}
                            distance="75px"
                            duration={1000}
                        >
                            <ImgHalf src={`${img}`} />
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </MainDiv>
    );
};
