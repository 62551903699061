import React from "react";
import ProductsHeader from "./ProductsSections/ProductsHeader/ProductsHeader";
import ProductOfferingSection from "./ProductsSections/ProductOfferingsSection/ProductOfferingsSection";

const ProductsPage = () => {
    return (
        <>
            <ProductsHeader />
            <ProductOfferingSection />
        </>
    );
};

export default ProductsPage;
