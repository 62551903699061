import React from "react";
import {
    MainDiv,
    MainContainer,
    ColContainer,
    LeftQuoteBox,
} from "./PricingInfo.styles";

import {
    Row,
    Col,
    Space,
    Form,
    Input,
    Button,
    notification,
    message,
} from "antd";
import EnquiryForm from "../../../../../components/EnquiryForm";
import { Fade } from "react-reveal";

const PricingInfo = () => {
    return (
        <MainDiv>
            <MainContainer>
                <Row gutter={100}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Fade left distance={"100px"} duration={1000}>
                            <ColContainer>
                                <h1>Unique to your organisation.</h1>
                                <LeftQuoteBox>
                                    <h1>Customised Pricing</h1>
                                    <p>
                                        Tailored Pricing for Your Success: At
                                        our core, we understand that each
                                        organisation is distinct. Our pricing
                                        model is meticulously crafted to cater
                                        to your unique needs, whether you're in
                                        higher education or business. It's
                                        designed to align with factors like your
                                        scale and the content areas your chat
                                        agent should cover. This way, you get a
                                        personalised and cost-effective solution
                                        that empowers your success, no matter
                                        your industry or focus.
                                    </p>
                                </LeftQuoteBox>
                            </ColContainer>
                        </Fade>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Fade right distance={"100px"} duration={1000}>
                            <ColContainer>
                                <EnquiryForm SubmitText={"Request Quote"} />
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </MainDiv>
    );
};

export default PricingInfo;
