import React from "react";
import { Fade } from "react-reveal";

const ChatHistoryPreview = ({ history, spyVar }) => {
    return (
        <div className="live-chat-conv-container">
            {history.map((message, index) => {
                let fadeProps = {};
                if (message.type === "bot") {
                    fadeProps.left = true;
                } else if (message.type === "user") {
                    fadeProps.right = true;
                }
                let delayVar;

                if (index < 8) {
                    delayVar = index * 350;
                } else {
                    delayVar = 200;
                }

                if (message.type === "continue") {
                    return (
                        <Fade
                            key={index}
                            {...fadeProps}
                            duration={1000}
                            delay={delayVar}
                            spy={spyVar}
                            appear={true}
                        >
                            <div className={`live-conv-window ${message.type}`}>
                                <div
                                    className="live-conv-window__text"
                                    style={{
                                        margin: "auto",
                                        maxWidth: "100%",
                                        // boxShadow:
                                        //     "0px 0px 10px 0px rgba(0,0,0,0.25)",
                                        // backgroundColor: "#e582b3 ",
                                        // color: "white",
                                        backgroundColor: "#f7f6f7 ",
                                        color: "#383838",
                                        textAlign: "center",
                                    }}
                                >
                                    <p>
                                        ...
                                        <br />
                                        {message.text}
                                        <br />
                                        ...
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    );
                } else {
                    return (
                        <Fade
                            key={index}
                            {...fadeProps}
                            delay={delayVar}
                            spy={spyVar}
                            appear={true}
                        >
                            <div className={`live-conv-window ${message.type}`}>
                                <div className="live-conv-window__text">
                                    <p>{message.text}</p>
                                </div>
                            </div>
                        </Fade>
                    );
                }
            })}
        </div>
    );
};

export default ChatHistoryPreview;
