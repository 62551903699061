import React from "react";
import { MainDiv } from "./PricingHeader.styles";
import { Flip } from "react-reveal";

const PricingHeader = () => {
    return (
        <MainDiv>
            {/* <Fade cascade down>
                <div>
                    <h1>PRICING</h1>
                    <h5>Plans for every organisation.</h5>
                </div>
            </Fade> */}
            <Flip cascade right duration={1500}>
                <h1>PRICING</h1>
            </Flip>
            <Flip cascade left duration={1500}>
                <h5>Plans for every organisation.</h5>
            </Flip>
        </MainDiv>
    );
};

export default PricingHeader;
