import React from "react";
import { MainDiv } from "./FAQHeader.styles";
import { Flip } from "react-reveal";

const FAQHeader = () => {
    return (
        <MainDiv>
            <Flip cascade right duration={1500}>
                <h1>F.A.Qs</h1>
            </Flip>
        </MainDiv>
    );
};

export default FAQHeader;
