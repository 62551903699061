import React from "react";
import { MainDiv, PartnerSlide, PartnerImage } from "./PartnershipsSection.styles";
import MicrosoftImage from "../../../../../common/img/microsoft-partner.webp";
import StoneNChalkImage from "../../../../../common/img/stone&chalklogo.svg";
import CsiroLogoImage from "../../../../../common/img/csirologo.png";
import { Carousel, Row, Col } from "antd";

const PartnershipsSection = () => {
    return (
        <MainDiv>
            <Row>
                <Col span={24}>
                    <h1>Industry Wide Endorsement</h1>
                </Col>
            </Row>
            <Row gutter={[0, 26]}>
                <Col xs={24} md={8}>
                    <PartnerSlide>
                        <PartnerImage src={MicrosoftImage} />
                    </PartnerSlide>
                </Col>
                <Col xs={24} md={8}>
                    <PartnerSlide>
                        <PartnerImage src={StoneNChalkImage} />
                    </PartnerSlide>
                </Col>
                <Col xs={24} md={8}>
                    <PartnerSlide>
                        <PartnerImage src={CsiroLogoImage} />
                    </PartnerSlide>
                </Col>
            </Row>
        </MainDiv>
    );
};

export default PartnershipsSection;
