import React, { useState } from "react";
import {
    LogoImage,
    MainDiv,
    PamphletImage,
    RequestButton,
    PamphletDiv,
} from "./RequestDemoSection.styles";

import Logo from "../../../../../common/img/LogoTransparent.png";
import Pamphlet from "../../../../../common/img/infoPamphlet.webp";
import { Button } from "antd";
import EnquiryFormModal from "../../../../../components/EnquiryFormModal";

const RequestDemoSection = () => {
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };
    return (
        <MainDiv>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <LogoImage src={Logo} />
                <h1>GALAH AI</h1>
                <h3>Contact - 03 9309 4000</h3>
                <RequestButton onClick={showModal} type="primary">
                    Request Demo
                </RequestButton>

                <EnquiryFormModal visible={modalVisible} onCancel={handleCancel} />
            </div>

            <PamphletDiv>
                <a href="https://www.galah.ai/GalahAI_Info_Pamphlet.pdf">
                    <PamphletImage src={Pamphlet} />
                </a>
                <h4>
                    <a
                        href="https://www.galah.ai/GalahAI_Info_Pamphlet.pdf"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        Get our information pamphlet!
                    </a>
                </h4>
            </PamphletDiv>
        </MainDiv>
    );
};

export default RequestDemoSection;
