import React, { useEffect, useRef, useState } from "react";
import {
    ConversationContainer,
    MainDiv,
    SectionHeaderContainer,
    TabCard,
    TabsContainer,
    TextContainer,
    UseCaseContainer,
    UseCasesMainDiv,
    UseCasesSectionMainContainer,
    WowText,
} from "./UseCasesSection.styles";
import { Card, Tabs, TabPane } from "antd";
import { Fade, Flip, Zoom } from "react-reveal";
import { ReactComponent as WhiteWavesTop } from "../../../../../common/img/WhiteWavesTop.svg";
import { ReactComponent as WhiteWavesBottom } from "../../../../../common/img/WhiteWavesBottom.svg";
import YourDataSection from "../YourDataSection/YourDataSection";
import { RightOutlined } from "@ant-design/icons";
import ChatHistoryPreview from "./ChatExample";
import { act } from "react-dom/test-utils";

// HISTORIES
const useCaseHistoryList = [
    [
        {
            type: "user",
            text: "Hi, is Dr. Mitchell available this Wednesday?",
        },
        {
            type: "bot",
            text: "Hi, unfortunately she is not available on Wednesdays. Her next availability is next Thursday afternoon.",
        },
        {
            type: "user",
            text: "Hmm ok. I’m unwell and will be free only this coming Wednesday. What should I do?",
        },
        {
            type: "bot",
            text: "Would you like me to arrange an appointment with Dr. Kimberly? She is one of our orthodontists and she has availability this coming Wednesday.",
        },
        {
            type: "user",
            text: "That would be great. Let’s book an appointment with Dr. Kimberly then.",
        },
        {
            type: "bot",
            text: "Sure, I can arrange that for you. Have you been to our clinic before?",
        },
        {
            type: "user",
            text: "No, this will be my first visit.",
        },
        {
            type: "bot",
            text: "Alright, welcome! I'll need some basic information to set up your appointment. Could you provide your full name and a contact number, please?",
        },
        {
            type: "continue",
            text: "Agent collects information and emails the details for the appointment.",
        },
        {
            type: "bot",
            text: "You're welcome! I emailed you the details for your appointment. We look forward to seeing you on Wednesday. Have a great day!",
        },
    ],
    [
        {
            type: "user",
            text: "Hi, I was wondering if the Range Rover Velar has any 7 seat configurations?",
        },
        {
            type: "bot",
            text: "Hi, Range Rover Velar has only 5 seats. However, our Discovery models have 7 seats and would be a perfect choice for large families.",
        },
        {
            type: "user",
            text: "Okay. Does it have a petrol engine option?",
        },
        {
            type: "bot",
            text: "Yes, you can choose from both diesel or premium unleaded petrol engines.",
        },
        {
            type: "user",
            text: "Okay thank you.",
        },
        {
            type: "bot",
            text: "No worries. Before you leave, if you like, I can arrange a test drive for you so you can experience our new Discovery models.",
        },
        {
            type: "user",
            text: "That would be great.",
        },
        {
            type: "bot",
            text: "Sure. Can I grab your name please?",
        },
        {
            type: "continue",
            text: "Agent collects information and arranges for a test drive.",
        },
        {
            type: "bot",
            text: "Thank you for your interest. I have just emailed you a calendar reminder for your upcoming test drive.",
        },
    ],
    [
        {
            type: "user",
            text: "Hi, I'm looking to buy a new smart TV.",
        },
        {
            type: "bot",
            text: "I'd be happy to assist you. What size and budget are you considering for your new smart TV?",
        },
        {
            type: "user",
            text: "I'm thinking of something around 55 inches, and my budget is around $1,000",
        },
        {
            type: "bot",
            text: "Great choice! For a 55-inch smart TV in that budget, we have several options. Are there any specific features you're looking for?",
        },
        {
            type: "user",
            text: "I want a 4K resolution. Also, I'd like it to be compatible with my existing home automation system.",
        },
        {
            type: "bot",
            text: "Understood. Considering your requirements, I recommend the Samsung Q60A 55-inch 4K QLED Smart TV.",
        },
        {
            type: "continue",
            text: "Agent provides further assistance in selecting the product.",
        },
        {
            type: "bot",
            text: "Excellent! You can proceed to checkout, and we'll take care of the rest.",
        },
    ],
    [
        {
            type: "user",
            text: "Hello, I have an appointment coming up for tomorrow at 11am to get my hair done. But something just came up and I need to reschedule.",
        },
        {
            type: "bot",
            text: "Sure, I can help you with that. Can I get your name and phone number please?",
        },
        {
            type: "user",
            text: "Lisa Green, 0441-123-456.",
        },
        {
            type: "bot",
            text: "Great. Which day suits you best?",
        },
        {
            type: "user",
            text: "It would be nice if I can come by tomorrow anytime between 2 and 4 pm.",
        },
        {
            type: "bot",
            text: "Unfortunately due to high demand for our services, the earliest we can fit you in is 5:15 pm tomorrow. Does that work for you?",
        },
        {
            type: "user",
            text: "Yeah ok.",
        },
        {
            type: "bot",
            text: "Great, I have rescheduled your appointment and sent you a confirmation text message. Anything else I can help you with today, Ms. Green?",
        },
        {
            type: "user",
            text: "All good, thank you.",
        },
    ],
    [
        {
            type: "user",
            text: "Hi, do you have any availability for the digital literacy seminar coming up next week?",
        },
        {
            type: "bot",
            text: "Yes, we have a few seats left. Would you like me to help you with your registration for this seminar?",
        },
        { type: "user", text: "Yes please." },
        { type: "bot", text: "What is your email address?" },
        {
            type: "continue",
            text: "Agent collects information and registers customer for the seminar.",
        },
        {
            type: "bot",
            text: "Thank you for your interest. I have sent a confirmation email to your address. Is there anything else that I can help with?",
        },
    ],
];
const useCasesList = [
    {
        label: "Appointments",
    },
    {
        label: "Leads",
    },
    {
        label: "Sales",
    },
    {
        label: "Bookings",
    },

    {
        label: "Registration",
    },
];
const useCaseDescriptions = [
    <div>
        <h2>Use Case #1: Simplified Customer Appointments</h2>
        <br />
        <p>
            Our AI Chat Agent is designed to simplify and streamline customer
            appointments. Say goodbye to lengthy phone calls and hello to
            convenience!
        </p>
        <br />
        <ul>
            <li>
                <strong>Effortless Scheduling:</strong> Need to book, change, or
                cancel an appointment? Simply ask about availability and let our
                AI Agent do the rest.
            </li>
            <li>
                <strong>Personalized Recommendations:</strong> Get expert
                recommendations based on your preferences and needs for our
                appointment..
            </li>
        </ul>
        <br />
    </div>,
    <div>
        <h2>Use Case #2: Optimised Lead Generation</h2>
        <br />
        <p>
            Our AI Chat Agent is a key lead generation asset for prospective
            customers inquiring about a range of services and products.
        </p>
        <br />

        <ul>
            <li>
                <strong>Immediate Responses:</strong> Provides swift, accurate
                answers to queries about various services and products.
            </li>
            <li>
                <strong>Tailored Communication:</strong> Provides customised
                information and relevant offers directly, aligning with the
                specific interests or needs of the inquiry.
            </li>
        </ul>
        <br />
    </div>,
    <div>
        <h2>Use Case #3: Accelerating Sales with AI</h2>
        <br />
        <p>
            Elevate your sales strategy with our AI Chat Agent, an essential
            tool for connecting with your customers exploring a variety of
            services and products.
        </p>
        <br />

        <ul>
            <li>
                <strong>Rapid Sales Response:</strong> Offers immediate,
                accurate answers to customer inquiries about products and
                services, ensuring a swift sales process.
            </li>
            <li>
                <strong>Customised Sales Solutions:</strong> Provides tailored
                information and relevant promotions directly addressing the
                individual needs and interests of each customer.
            </li>
        </ul>
        <br />
    </div>,
    <div>
        <h2>Use Case #4: Efficient Booking Management </h2>
        <br />
        <p>
            Experience the ultimate convenience and flexibility for your
            bookings with our AI Chat Agent tailored for your specific industry.
        </p>
        <br />
        <ul>
            <li>
                <strong>Hassle-Free Bookings:</strong> Whether you're looking to
                schedule, modify, or cancel a booking, our chat agent handles it
                effortlessly.
            </li>
            <li>
                <strong>Tailored Booking Options:</strong> Receive personalised
                suggestions suited to your specific preferences and
                requirements.
            </li>
        </ul>
        <br />
    </div>,
    <div>
        <h2>Use Case #5: Streamlined Registration Process</h2>
        <br />

        <p>
            Our AI Chat Agent streamlines the registration process for various
            events. It offers a conversational and user-friendly approach,
            making registration quick and engaging.
        </p>
        <br />

        <ul>
            <li>
                <strong>Simplified Registration:</strong> Eliminates complex
                forms, allowing for easy and natural conversation-style
                registrations.
            </li>
            <li>
                <strong>Personalised Interaction:</strong> Gathers participant
                details in a friendly manner, enhancing accuracy and engagement.
            </li>
        </ul>
        <br />
    </div>,
];

const UseCasesSection = () => {
    const [activeItem, setActiveItem] = useState(0);

    useEffect(() => {
        const convWindow = document.getElementById("convWindowMainPage");
        convWindow.scrollTop = 0;
    }, [activeItem]);

    return (
        <>
            <UseCasesSectionMainContainer>
                <SectionHeaderContainer>
                    <Fade down>
                        <h1>
                            Recognise the Possibilities with{" "}
                            <span style={{ color: "lightpink" }}>Galah AI</span>
                        </h1>
                        <br />
                        <h3>
                            Below are some use cases to illustrate what our AI
                            chat agent technology can offer to your customers.
                        </h3>
                    </Fade>
                </SectionHeaderContainer>
                <UseCasesMainDiv>
                    <TextContainer>
                        <Fade left spy={activeItem} appear>
                            {useCaseDescriptions[activeItem]}
                        </Fade>
                    </TextContainer>
                    <UseCaseContainer>
                        <TabsContainer>
                            {useCasesList.map((item, index) => (
                                <TabCard
                                    $active={index == activeItem}
                                    onClick={() => setActiveItem(index)}
                                >
                                    <p>{item.label}</p>
                                </TabCard>
                            ))}
                        </TabsContainer>
                        <ConversationContainer id="convWindowMainPage">
                            <ChatHistoryPreview
                                history={useCaseHistoryList[activeItem]}
                                spyVar={activeItem}
                            />
                        </ConversationContainer>
                    </UseCaseContainer>
                </UseCasesMainDiv>
            </UseCasesSectionMainContainer>
        </>
    );
};

export default UseCasesSection;
