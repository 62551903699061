import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "antd";

export const NavHeader = styled.header.attrs({
    className: "NavHeader",
})`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    height: 2rem;
    transition: all 0.2s;
    background-color: ${(props) =>
        props.$scrolled ? "rgba(255, 255, 255, 0.7)" : "transparent"};
    backdrop-filter: ${(props) => (props.$scrolled ? "blur(10px)" : "none")};
    padding: ${(props) => (props.$scrolled ? "10px 0" : "20px 0")};
    box-shadow: ${(props) =>
        props.$scrolled
            ? "0px 1px 5px rgba(165, 165, 165, 0.2)"
            : "0px 0px 0px"};
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const LinksContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const NavbarContainer = styled.nav.attrs({
    className: "NavbarContainer",
})`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const linkStyles = css`
    text-decoration: none;
    color: #355057;
    font-weight: 500;
    &:hover {
        font-weight: 600;
        color: #598995;
        text-shadow: 0px 1px 1px rgb(128, 128, 128) 10%;
    }
`;

export const LogoImage = styled.img`
    height: 2.75rem;
    margin-left: 1rem;
    /* margin-right: 0.25rem; */
    /* background: linear-gradient(#503b53, #2a1f29); */
    /* padding: 5px; */
    &:hover {
        text-shadow: 0px 1px 1px rgb(128, 128, 128) 50%;
    }
`;

export const HomeLink = styled(Link)`
    font-size: 1rem;
    ${linkStyles};
`;

export const BrandLink = styled(Link)`
    margin-right: 1rem;
    ${linkStyles};
`;

export const RequestButton = styled(Button)`
    font-size: 1rem;
    margin-right: 1rem;
`;

export const LoginButton = styled(Button)`
    font-size: 1rem;
    margin-right: 1rem;
`;
