import React from "react";

import LandingHeader from "./LandingSections/HeroSection/HeroSection";
import FirstSection from "./LandingSections/FirstSection/FirstSection";
import CardSection from "./LandingSections/CardSection/CardSection";
import YourDataSection from "./LandingSections/YourDataSection/YourDataSection";
import MultilingualSection from "./LandingSections/MultilingualSection/MultilingualSection";
import AdaptableSection from "./LandingSections/AdaptableSection/AdaptableSection";
import WowSection from "./LandingSections/WowSection/WowSection";
import RequestDemoSection from "./LandingSections/RequestDemoSection/RequestDemoSection";
import ProductsSection from "./LandingSections/ProductsSection/ProductsSection";
import UseCasesSection from "./LandingSections/UseCasesSection/UseCasesSection";
import PartnershipsSection from "./LandingSections/Partnerships/PartnershipsSection";

const WelcomePage = () => {
    return (
        <>
            <LandingHeader />
            <FirstSection />
            <UseCasesSection />
            <PartnershipsSection />
            <YourDataSection />
            <ProductsSection />

            <MultilingualSection />
            <AdaptableSection />
            <RequestDemoSection />
        </>
    );
};
export default WelcomePage;
