/* eslint-disable no-console */

import React from "react";
import { ChatBox, ChatSection, IFrame, MainDiv } from "./Demo.styles";

const Demo = ({ source }) => {
    return (
        <MainDiv>
            <ChatSection>
                <ChatBox>
                    <IFrame
                        src={source}
                        width="100%"
                        height="100%"
                        frameBorder={0}
                    />
                </ChatBox>
            </ChatSection>
        </MainDiv>
    );
};

export default Demo;
