import { Button } from "antd";
import styled from "styled-components";

export const PamphletDiv = styled.div`
    padding: 1rem;
    margin: 0rem 0rem;
    background: #ffffff;
    box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 5rem;

    @media (max-width: 600px) {
        margin-left: 10rem;
        margin-right: 10rem;
    }
`;

export const MainDiv = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#3b4f53, #2a1f29);

    @media (max-width: 600px) {
        flex-direction: column;
        height: 60vh;
    }

    h1 {
        font-size: 3vw;
        background: -webkit-linear-gradient(#edbabf, #ba828e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 1024px) {
            font-size: 5vw;
        }

        @media (max-width: 600px) {
            font-size: 8vw;
        }
    }
    h3 {
        font-size: 0.8vw;
        background: -webkit-linear-gradient(#edbabf, #ba828e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        margin-bottom: 1rem;

        @media (max-width: 1024px) {
            font-size: 1vw;
        }

        @media (max-width: 600px) {
            font-size: 3vw;
        }
    }

    p {
        font-size: 1.2vw;
        font-weight: 600;
        @media (max-width: 1024px) {
            font-size: 2vw;
        }

        @media (max-width: 600px) {
            font-size: 2.5vw;
        }
    }
`;

export const LogoImage = styled.img`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 20vh;
`;

export const PamphletImage = styled.img`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
    height: 30vh;

    @media (max-width: 600px) {
        height: 20vh;
    }
`;

export const RequestButton = styled(Button)`
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1rem;
`;
