import React, { useEffect, useState } from "react";

import {
    FirstDiv,
    InfoBottomText,
    InfoTopText,
    MainContainer,
    SplitLeft,
    SplitRight,
    LightPinkSpan,
} from "./FirstSection.styles";
import { Fade, Flip, LightSpeed, Zoom } from "react-reveal";

import { Col, Row } from "antd";

import BrainFig from "../../../../../common/img/FirstSectionFigure.png";

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const FirstSection = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const spanStyle = {
        whiteSpace: "nowrap", // Prevent word wrapping
        display: "inline-block",
        // margin: "0 10px", // Add margin to separate words
    };

    return (
        <FirstDiv>
            <MainContainer>
                <Fade left distance={"200px"} duration={1800}>
                    <h1>
                        Revolutionise Your Customer Engagement with Intelligent
                        AI Technology.
                    </h1>
                </Fade>

                <Row justify={"center"} align={"middle"} gutter={16}>
                    <Col span={windowSize.innerWidth > 600 ? 12 : 24}>
                        <Fade up distance="100px" duration={1000}>
                            <SplitLeft>
                                <InfoTopText>
                                    With{" "}
                                    <LightPinkSpan>
                                        Conversational AI
                                    </LightPinkSpan>{" "}
                                    from{" "}
                                    <LightPinkSpan>Customised</LightPinkSpan> AI
                                    Chat Agents created for{" "}
                                    <LightPinkSpan>Australian</LightPinkSpan>{" "}
                                    businesses and organisations.
                                </InfoTopText>
                                <br />
                                <InfoBottomText
                                    style={{
                                        fontSize:
                                            "max(12px,min(.159375vw,14px))",
                                    }}
                                >
                                    Experience the future of customer engagement
                                    with our AI Chat Agent – the ultimate
                                    solution for personalised, interactive, and
                                    efficient interactions. Our AI Chat Agent is
                                    not just some chatbot; it's your dynamic
                                    virtual assistant capable of performing
                                    custom actions tailored to your needs, from
                                    retrieving account details to sending emails
                                    seamlessly. It effortlessly handles
                                    applications in a natural, conversational
                                    manner, and can even email completed
                                    applications to your designated address.
                                    What sets it apart is its ability to engage
                                    with your database, gathering insightful
                                    responses in real-time. Elevate your
                                    customer service to new heights and
                                    streamline your processes with our AI Chat
                                    Agent today.
                                </InfoBottomText>
                            </SplitLeft>
                        </Fade>
                    </Col>
                    <Col span={windowSize.innerWidth > 600 ? 12 : 24}>
                        <Fade right distance="75px" duration={1000}>
                            <SplitRight src={`${BrainFig}`} />
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </FirstDiv>
    );
};

export default FirstSection;
