export const CustomerList = [
    {
        title: `Speed and Efficiency: `,
        body: `Provide faster and more efficient service.`,
    },
    {
        title: `24/7 Availability: `,
        body: `Meet customers in today's fast-paced world with round-the-clock support.`,
    },
    {
        title: `Digital Transformation: `,
        body: `Join us in the digital transformation journey.`,
    },
    {
        title: `Connect Anywhere: `,
        body: `Reach customers where they are, regardless of location.`,
    },
    {
        title: `Say Goodbye to the Old: `,
        body: `Bid farewell to outdated methods.`,
    },
    {
        title: `Embrace the New Era: `,
        body: `Welcome the new era of digital customer interaction.`,
    },
];

export const AllStaffList = [
    {
        title: `Data Security:`,
        body: `Protect your intellectual property, confidential information, and client data with Galah AI's secure, regulated environment.`,
    },
    {
        title: `Peace of Mind: `,
        body: `Ensure all interactions and data remain safe and confidential.`,
    },
    {
        title: `Local Hosting:`,
        body: `Trust a locally-hosted AI chat agent solution for enhanced security.`,
    },
    {
        title: `Boost Productivity:`,
        body: `Galah AI empowers your team to accomplish tasks more efficiently, reducing workload pressure.`,
    },
    {
        title: `Innovative Workplace: `,
        body: `Cultivate a dynamic and innovative workplace atmosphere with AI chat agent technology.`,
    },
    {
        title: `Privacy Assurance:`,
        body: `Use our private and secure alternative to ChatGPT for utmost data protection.`,
    },
];

export const AuthorizedList = [
    {
        title: `Custom Training:`,
        body: `Train our platform using your unique data sources for a tailored AI chat agent experience.`,
    },
    {
        title: `Code-Free Interaction: `,
        body: `Say goodbye to complex coding and converse with your database like a colleague.`,
    },
    {
        title: `Natural Language Queries: `,
        body: `Our advanced AI understands and responds to your natural language queries instantly.`,
    },
    {
        title: `Real-Time Responses: `,
        body: `Get accurate, easy-to-understand answers to complex questions in real-time.`,
    },
    {
        title: `Data Empowerment: `,
        body: `Enhance staff decision-making with effortless data analysis and trend understanding.`,
    },
    {
        title: `Innovative User Experience:`,
        body: `Choose Galah AI for a code-free, user-friendly data interaction that boosts productivity.`,
    },
];
