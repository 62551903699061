import styled from "styled-components";
import DivBG from "../../../../../common/img/WowBackground.svg";
import withReveal from "react-reveal/makeCarousel";
import { Flip } from "react-reveal";

export const MainDiv = styled.div`
    height: 75vh;
    width: 100%;
    background-image: url(${DivBG});
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TextContainer = styled.div`
    padding: 40px;
    border-radius: 30px;
    margin: 8vw 8vw;
    text-align: left;

    h1 {
        font-size: 3vw;

        @media (max-width: 1024px) {
            font-size: 4vw;
        }

        @media (max-width: 600px) {
            font-size: 8vw;
        }
    }

    p {
        font-size: 1vw;
        font-weight: 600;
        @media (max-width: 1024px) {
            font-size: 3.5vw;
        }

        @media (max-width: 600px) {
            font-size: 3.5vw;
        }
    }

    background-color: #ffffff38;
    backdrop-filter: blur(10px);
`;
