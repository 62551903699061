import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled, { keyframes } from "styled-components";

export const MainDiv = styled.div`
    background: gray;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    h1 {
        font-size: 2vw;
        color: white;
        text-align: center;
        padding-bottom: 20px;
        @media (max-width: 1024px) {
            font-size: 5vw;
        }

        @media (max-width: 600px) {
            font-size: 5vw;
        }
    }

    p {
        font-size: 1.2vw;
        font-weight: 600;
        @media (max-width: 1024px) {
            font-size: 2vw;
        }

        @media (max-width: 600px) {
            font-size: 2.5vw;
        }
    }
`;

export const PartnerSlide = styled.div.attrs({
    className: "PartnerSlide",
})`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
`;

export const PartnerImage = styled.img.attrs({
    className: "PartnerImage",
})`
    /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    /* width: 30%; */
    height: 125px;
    @media (max-width: 1024px) {
        height: 125px;
    }

    @media (max-width: 600px) {
        height: 125px;
    }
`;
