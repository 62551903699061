import { Link } from "react-router-dom";
import AppRoutes from "../../common/AppRoutes";
import { Col, Divider, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
    BrandLink,
    NavHeader,
    LinksContainer,
    HomeLink,
    LogoContainer,
    LogoImage,
    LoginButton,
} from "./Navbar.styles";
import LogoImg from "../../common/img/LogoTransparent.png";
import { LoginOutlined, MenuOutlined } from "@ant-design/icons";

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        setScrolled(offset > 80);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const [drawerVisible, setDrawerVisible] = useState(false);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const onClose = () => {
        setDrawerVisible(false);
    };

    const renderMenu = () => (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Divider orientation="left">
                <BrandLink onClick={onClose} to={AppRoutes.PRODUCTS_PAGE}>
                    PRODUCTS
                </BrandLink>
            </Divider>
            <Divider orientation="left">
                <BrandLink onClick={onClose} to={AppRoutes.SERVICES_PAGE}>
                    SERVICES
                </BrandLink>
            </Divider>
            <Divider orientation="left">
                <BrandLink onClick={onClose} to={AppRoutes.PRICING_PAGE}>
                    PRICING
                </BrandLink>
            </Divider>
            <Divider orientation="left">
                <BrandLink onClick={onClose} to={AppRoutes.ABOUT_US_PAGE}>
                    ABOUT US
                </BrandLink>
            </Divider>
            <Divider orientation="left">
                <BrandLink onClick={onClose} to={AppRoutes.FAQ_PAGE}>
                    FAQ
                </BrandLink>
            </Divider>
            <Divider orientation="center">
                <LoginButton
                    type="primary"
                    ghost={true}
                    // size="small"
                    styles={{ height: "50px" }}
                    icon={<LoginOutlined />}
                >
                    <BrandLink
                        onClick={onClose}
                        to={"https://app.galahchat.au/"}
                    >
                        Login{" "}
                    </BrandLink>
                </LoginButton>
            </Divider>
        </div>
    );

    return (
        <NavHeader $scrolled={scrolled}>
            <Row align="middle">
                <Col xs={{ span: 8 }} sm={{ span: 4 }}>
                    <LogoContainer>
                        <Link to={AppRoutes.LANDING_PAGE}>
                            <LogoImage
                                src={`${LogoImg}`}
                                style={{ marginTop: "3px" }}
                            />
                        </Link>
                        <HomeLink to={AppRoutes.LANDING_PAGE}>
                            GALAH
                            <strong>
                                <span style={{ color: "#f68293" }}>.AI</span>
                            </strong>
                        </HomeLink>
                    </LogoContainer>
                </Col>
                <Col align={"center"} xs={{ span: 0 }} sm={{ span: 20 }}>
                    <LinksContainer>
                        <BrandLink to={AppRoutes.PRODUCTS_PAGE}>
                            PRODUCTS
                        </BrandLink>
                        <BrandLink to={AppRoutes.SERVICES_PAGE}>
                            SERVICES
                        </BrandLink>
                        <BrandLink to={AppRoutes.PRICING_PAGE}>
                            PRICING
                        </BrandLink>
                        <BrandLink to={AppRoutes.ABOUT_US_PAGE}>
                            ABOUT US
                        </BrandLink>
                        <BrandLink to={AppRoutes.FAQ_PAGE}>FAQ</BrandLink>
                        <BrandLink
                            onClick={onClose}
                            to="https://app.galahchat.au/"
                            target="_blank"
                            rel="noopener_noreferrer"
                        >
                            <LoginButton
                                type="primary"
                                ghost={true}
                                size="small"
                                styles={{ height: "30px" }}
                                icon={<LoginOutlined />}
                            >
                                Login
                            </LoginButton>
                        </BrandLink>
                    </LinksContainer>
                </Col>
                <Col align={"right"} xs={{ span: 16 }} sm={{ span: 0 }}>
                    <LinksContainer>
                        <MenuOutlined
                            style={{ marginRight: "1rem" }}
                            onClick={showDrawer}
                            size={20}
                        />
                    </LinksContainer>
                </Col>
            </Row>
            <Drawer
                title="Explore"
                placement="right"
                closable={false}
                onClose={onClose}
                open={drawerVisible}
                width="200"
            >
                {renderMenu()}
            </Drawer>
        </NavHeader>
    );
};

export default Navbar;
