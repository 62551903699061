import React from "react";
import {
    MainDiv,
    MainContainer,
    ColContainer,
    LeftQuoteBox,
} from "./FAQAccordion.styles";

import { Row, Col } from "antd";
import { Fade } from "react-reveal";
import Accordion from "./Accordion";

const FAQAccordion = () => {
    return (
        <MainDiv>
            <MainContainer>
                <Row gutter={100}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <ColContainer>
                            <Fade down distance={"100px"} duration={1000}>
                                <h1>Some frequently asked questions</h1>
                            </Fade>
                            <Fade up distance={"100px"} duration={1000}>
                                <LeftQuoteBox>
                                    <Accordion />
                                </LeftQuoteBox>
                            </Fade>
                        </ColContainer>
                    </Col>
                </Row>
            </MainContainer>
        </MainDiv>
    );
};

export default FAQAccordion;
