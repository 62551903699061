import styled from "styled-components";
import LandingSplash from "../../../../../common/img/LandingBackground.png";

export const LandingDiv = styled.div.attrs({
    className: "LandingContainer",
})`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100vh;

    background: url(${LandingSplash});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;

    /* Make the text responsive */
    @media (max-width: 668px) {
        text-align: center; /* Center-align text on smaller screens */
        align-items: center; /* Center-align text on smaller screens */
        justify-content: center;
        background-position-x: 60%;
    }
`;

// Add a styled component for the text content
export const LandingTextContainer = styled.div.attrs({
    className: "LandingTextContainer",
})`
    color: #355057; /* Text color */

    position: absolute;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: ease all 0.5s;
    padding: 10px;
    padding-right: 2.5%;
    border-radius: 20px;
    max-width: 800px;

    font-size: 2vh;
    font-weight: 600;
    align-items: last baseline;

    @media (max-width: 668px) {
        position: relative;
        max-width: 90%;
        margin-right: 0px;
        font-weight: 400;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        font-weight: 700;
        font-size: 1.75vh;
    }
`;

export const TopText = styled.div.attrs({
    className: "TopText",
})`
    text-align: left;
    margin-bottom: 10px;
    background: linear-gradient(to right, #f0f0f0, #ffffff);
    padding: 5px 15px;
    padding-right: 50px;
    @media (max-width: 640px) {
        align-self: center;
        background: linear-gradient(to right, #f0f0f0, #ffffffde);
        font-size: 100%;
        padding-right: 0px;
        padding: 10px 20px 10px 10px;
    }
`;

export const BottomText = styled.div`
    text-align: left;
    background: linear-gradient(220deg, #ffffff 0%, #ededed 100%);
    margin-top: 10px;
    padding: 7px;
    border-radius: 5px;
    font-size: 15px;
`;

export const RequestDemoButton = styled.div`
    padding: 10px 30px;
    margin-top: 20px;
    margin-right: 50px;
    margin-left: 50px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    border-radius: 30px;
    background: #efc6c1;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.1);
    color: #355057;
    transition: ease all 0.2s;
    user-select: none;
    align-self: center;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px rgba(210, 178, 185, 0.8);
    }
`;
