import styled from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 70vw;
    width: 70vw;
    margin-bottom: 5rem;
`;

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
    h1 {
        color: #f5b0bb;
        text-align: left;
        font-size: max(32px, min(2.96875vw, 52.5px));
    }
    h2 {
        font-size: max(20px, min(1.296875vw, 30.5px));
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    /* gap: 20px; */
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    gap: 10px;
    box-shadow: 0px 0px 10px #80808039;

    h1 {
        font-size: max(28px, min(2.03125vw, 39px));
        font-weight: 500;
    }
    h3 {
        font-weight: 500;
    }
    li {
        font-weight: 400;
        font-size: 18px;
    }
`;
