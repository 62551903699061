import React from "react";
import { MainDiv } from "./ServicesHeader.styles";
import { Flip } from "react-reveal";

const ServicesHeader = () => {
    return (
        <MainDiv>
            <Flip cascade right duration={1500}>
                <h1>SERVICES</h1>
            </Flip>
        </MainDiv>
    );
};

export default ServicesHeader;
