/* eslint-disable no-console */

import React from "react";
import { ChatBox, ChatSection, IFrame, MainDiv } from "./YarraDemo.styles";

const YarraDemo = () => {
    return (
        <MainDiv>
            <ChatSection>
                <ChatBox>
                    <IFrame
                        src="https://app.galahchat.au/chatbot-iframe/c3549253-ade7-41f1-a3d5-49251eca86fe"
                        width="100%"
                        height="100%"
                        frameBorder={0}
                    />
                </ChatBox>
            </ChatSection>
        </MainDiv>
    );
};

export default YarraDemo;
