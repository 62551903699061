import InnovateImg from "../../../../../common/img/ServicesImgs/InnovativeAI.png";
import TailoredImg from "../../../../../common/img/ServicesImgs/TailoredStrat.png";
import CustomAIImg from "../../../../../common/img/ServicesImgs/CustomAIDev.png";
import DataScienceImg from "../../../../../common/img/ServicesImgs/DataScience.png";
import AITrainingImg from "../../../../../common/img/ServicesImgs/AITraining.png";
import AIEthicsImg from "../../../../../common/img/ServicesImgs/AIEthics.png";
import ParterWithUsImg from "../../../../../common/img/ServicesImgs/PartnerWithUs.png";

export const SOList = [
    {
        title: "Innovative AI Solutions for Your Business",
        img: InnovateImg,
        body: "In a world where technology is the driving force of change, our AI & ML consultancy stands at the forefront of innovation. We specialize in creating custom AI solutions that propel your business into the future. With our guidance, you'll not only keep pace but set the standard in an ever-evolving digital landscape.",
    },
    {
        title: "Tailored AI Strategies and Solutions",
        img: TailoredImg,
        body: "We offer a strategic partnership to cultivate your AI vision. Our consultants excel in market analysis and roadmap development, ensuring your AI initiatives align with your business goals. With us, you'll stay competitive, minimize risk, and see a significant return on your investment.",
    },
    {
        title: "Custom AI Development",
        img: CustomAIImg,
        body: "Harness our expertise in machine learning, natural language processing, and predictive analytics. We build AI systems that are not just sophisticated but integrate flawlessly with your current operations, giving you that distinctive competitive advantage.",
    },
    {
        title: "Data Science & Analytics",
        img: DataScienceImg,
        body: "Data isn't just numbers; it's the narrative of your business waiting to be told. Our data scientists are storytellers who translate complex data into actionable insights, helping you to navigate trends and make strategic, data-driven decisions.",
    },
    {
        title: "AI Training & Support",
        img: AITrainingImg,
        body: "Our commitment extends beyond implementation. We empower your team with the knowledge and tools to leverage AI technologies effectively, ensuring enduring success and adaptability within your organization.",
    },
    {
        title: "AI Ethics and Compliance",
        img: AIEthicsImg,
        body: "AI is powerful and with great power comes great responsibility. We guide you through the ethical and regulatory issues, helping you implement AI solutions that are not only powerful but also principled and compliant.",
    },
    {
        title: "Why Partner with Us?",
        img: ParterWithUsImg,
        body: (
            <span>
                <strong>Expert Team</strong>: Our consultants are industry
                veterans with a proven track record in pioneering AI solutions.{" "}
                <br />
                <strong>Customized Solutions</strong>: We believe in the power
                of personalization, ensuring our services are tailored to
                address your unique challenges. <br />
                <strong>Client-Centric Approach</strong>: We prioritize a
                partnership that respects your vision and goals. Collaborating
                closely, we are committed to realizing your objectives with
                attention to detail.
                <br />
                <strong>Ethical & Transparent</strong>: We hold ourselves to the
                highest ethical standards, guaranteeing transparency in the AI
                solutions we deliver."
            </span>
        ),
    },
];
