import styled from "styled-components";

export const MainDiv = styled.div.attrs({
    className: "MainDiv",
})`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 100%;
    /* text-align: right; */
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 80vw;
    min-width: 80vw;

    h1 {
        font-weight: 800;
        color: #f09fad;
        font-size: max(38px, min(2.5125vw, 54px));
    }

    h5 {
        font-weight: 500;
        font-size: max(16px, min(1.09375vw, 21px));
        margin-bottom: 10px;
    }

    @media (max-width: 688px) {
        max-width: 90vw;
    }
`;

export const InfoTopText = styled.p`
    font-weight: 500;
    font-size: max(18px, min(1.09375vw, 21px));
`;

export const InfoBottomText = styled.p`
    font-weight: 500;
    font-size: max(16px, min(0.859375vw, 16.5px));
`;
export const TextHalf = styled.div``;

export const ImgHalf = styled.img`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
    width: 100%;
    box-shadow: 0 0 8px 8px white inset;
`;

// style={{
//     fontSize:
//         "max(12px,min(.159375vw,14px));",
// }}
