import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./components/Navigation/Navbar";
import BrandFooter from "./components/Footer/Footer";
import ChangePassword from "./components/Account/ChangePassword";

import NotFound from "./pages/publicPages/NotFoundPage/NotFound";
import Login from "./pages/publicPages/LoginPage/Login";
import LandingPage from "./pages/publicPages/LandingPage/LandingPage";
import AppRoutes from "./common/AppRoutes";
import HumeDemo from "./pages/HumeDemo/HumeDemo";
import PricingPage from "./pages/publicPages/PricingPage/PricingPage";
import AboutUsPage from "./pages/publicPages/AboutUsPage/AboutUsPage";
import FAQPage from "./pages/publicPages/FAQPage/FAQPage";
import ProductsPage from "./pages/publicPages/ProductsPage/ProductsPage";
import ServicesPage from "./pages/publicPages/ServicesPage/ServicesPage";
import YarraDemo from "./pages/YarraDemo/YarraDemo";
import DeakinDemo from "./pages/DeakinDemo/DeakinDemo";
import Demo from "./pages/Demo/Demo";

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top of the page whenever the route changes
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [location.pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <ConditionalNavbar />

                <Routes>
                    {/* the id prop here is passed to the component */}
                    {/* which is then recovered via the useParams() hook */}
                    {/* it is also passed down from the parent component to its children as a prop */}
                    <Route
                        index
                        element={<LandingPage />}
                        path={AppRoutes.LANDING_PAGE}
                    />
                    <Route element={<Login />} path={AppRoutes.LOGIN} />
                    <Route
                        element={<ChangePassword />}
                        path={AppRoutes.CHANGE_PASSWORD}
                    />
                    <Route
                        element={<ProductsPage />}
                        path={AppRoutes.PRODUCTS_PAGE}
                    />
                    <Route
                        element={<ServicesPage />}
                        path={AppRoutes.SERVICES_PAGE}
                    />
                    <Route
                        element={<PricingPage />}
                        path={AppRoutes.PRICING_PAGE}
                    />
                    <Route
                        element={<AboutUsPage />}
                        path={AppRoutes.ABOUT_US_PAGE}
                    />
                    <Route element={<FAQPage />} path={AppRoutes.FAQ_PAGE} />

                    <Route
                        element={<YarraDemo />}
                        path={AppRoutes.YARRA_DEMO}
                    />
                    <Route
                        element={<DeakinDemo />}
                        path={AppRoutes.DEAKIN_DEMO}
                    />
                    <Route
                        element={
                            <Demo
                                source={
                                    "https://app.galahchat.au/chatbot-iframe/7d49f91b-619c-4e68-97f6-4293aac3ae14"
                                }
                            />
                        }
                        path={AppRoutes.VLA_DEMO}
                    />
                    <Route element={<HumeDemo />} path={AppRoutes.HUME_DEMO} />

                    <Route element={<NotFound />} path="*" />
                </Routes>

                <ConditionalFooter />
            </div>
        </Router>
    );
}

const ConditionalNavbar = () => {
    const location = useLocation();
    if (location.pathname.startsWith("/chatbot-iframe")) {
        return null;
    }
    return <Navbar isDemo={false} />;
};

const ConditionalFooter = () => {
    const location = useLocation();
    if (location.pathname.startsWith("/chatbot-iframe")) {
        return null;
    }
    if (location.pathname.startsWith("/demo")) {
        return null;
    }
    return <BrandFooter />;
};

export default App;
