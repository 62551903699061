import styled from "styled-components";
import BrainFig from "../../../../../common/img/YourDataFigure.png";

export const FirstDiv = styled.div.attrs({
    className: "FirstDiv",
})`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    width: 100%;
    /* box-shadow: inset 0px 5px 5px #d3d3d364; */
    text-align: left;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 80vw;

    /* padding: 4rem;
    box-shadow: 0px 0px 10px #80808039;
    border-radius: 20px; */

    h1 {
        font-weight: 800;
        font-size: max(38px, min(2.5125vw, 54px));
    }

    h5 {
        font-weight: 500;
        font-size: max(16px, min(1.09375vw, 21px));
        margin-bottom: 10px;
    }

    @media (max-width: 688px) {
        max-width: 90vw;
    }
`;

export const SplitLeft = styled.div`
    /* height: 50vh; */
`;

export const InfoTopText = styled.p`
    font-weight: 500;
    font-size: max(18px, min(1.09375vw, 21px));
    /* margin: rem 0px; */
`;

export const InfoBottomText = styled.p`
    font-weight: 400;
    font-size: max(16px, min(0.859375vw, 16.5px));
`;

export const SplitRight = styled.img`
    /* background: url(${BrainFig}); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    /* height: 40vh; */
`;
