/* eslint-disable no-console */

import React from "react";
import { ChatBox, ChatSection, IFrame, MainDiv } from "./DeakinDemo.styles";

const DeakinDemo = () => {
    return (
        <MainDiv>
            <ChatSection>
                <ChatBox>
                    <IFrame
                        src="https://app.galahchat.au/chatbot-iframe/1b624c4a-588e-448c-9860-89420480edf0"
                        width="100%"
                        height="100%"
                        frameBorder={0}
                    />
                </ChatBox>
            </ChatSection>
        </MainDiv>
    );
};

export default DeakinDemo;
