import React from "react";
import { MainDiv } from "./AboutUsHeader.styles";
import { Flip } from "react-reveal";

const AboutUsHeader = () => {
    return (
        <MainDiv>
            <Flip cascade right duration={1500}>
                <h1>ABOUT US</h1>
            </Flip>
        </MainDiv>
    );
};

export default AboutUsHeader;
