import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import PhoneInput from "antd-phone-input";

const EnquiryForm = ({ SubmitText, onSuccess }) => {
    const [form] = Form.useForm();
    const [processingRequest, setProcessingRequest] = useState(false);

    const onFinish = async (values) => {
        // Here you can format the values if needed

        setProcessingRequest(true);
        try {
            const response = await fetch(
                process.env.REACT_APP_BACKEND_URL + "/api/emails/request_demo",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                }
            );

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            message.success("Request submitted successfully!");

            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error("Failed to submit form:", error);
            message.error("Failed to submit the form, please try again.");
            setProcessingRequest(false);
        }
    };

    return (
        <Form
            labelCol={14}
            layout={"vertical"}
            form={form}
            style={{ width: "100%" }}
            onFinish={onFinish}
            disabled={processingRequest}
        >
            <Form.Item label="Full Name" name="full_name">
                <Input placeholder="Please enter your full name" />
            </Form.Item>
            <Form.Item label="Title" name="title">
                <Input placeholder="Please enter your title" />
            </Form.Item>
            <Form.Item label="Email" name="email">
                <Input placeholder="Please enter your email" />
            </Form.Item>
            <Form.Item label="Contact Number" name="phone">
                <Input placeholder="Please enter your contact number" />
                {/* <PhoneInput
                    value={{
                        countryCode: "61",
                        areaCode: "3",
                        isoCode: "au",
                    }}
                    onlyCountries={["au"]}
                    disableDropdown
                    placeholder="Please enter your contact number"
                /> */}
            </Form.Item>
            <Form.Item label="Company" name="company">
                <Input placeholder="Please enter your company name" />
            </Form.Item>
            <Form.Item label="Describe Your Business" name="description">
                <Input.TextArea
                    rows={5}
                    placeholder="Explain what you do and what you are looking for from a chat agent"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {SubmitText}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default EnquiryForm;
