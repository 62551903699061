/* eslint-disable no-console */

import React from "react";
import {
    ChatBox,
    ChatSection,
    IFrame,
    LeftContainer,
    MainContainer,
    MainDiv,
    RightContainer,
} from "./HumeDemo.styles";
// import HumeDemoSplash from "../../components/img/HumeDemoSplash.png";

const HumeDemo = () => {
    return (
        <MainDiv>
            <ChatSection>
                <ChatBox>
                    <IFrame
                        src="https://app.galahchat.au/chatbot-iframe/849dd223-19d1-4f41-ad1a-1dd74b257309"
                        width="100%"
                        height="100%"
                        frameBorder={0}
                    />
                </ChatBox>
            </ChatSection>
        </MainDiv>
    );
};

export default HumeDemo;
