import styled from "styled-components";

import TileBg from "../../../../../common/img/TileBackground.svg";
export const MainDiv = styled.div.attrs({
    className: "MainDiv",
})`
    background: url(${TileBg});
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    width: 100%;
    padding: 5vh 0;
    box-shadow: inset 0px 5px 5px #8080805e;
`;

export const CardContainer = styled.div.attrs({
    className: "CardContainer",
})`
    display: flex;
    width: 100vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

export const CardFront = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 35rem;
    width: 35rem;

    h1 {
        font-size: max(38px, min(2.8125vw, 54px));
        margin-bottom: 20px;
    }
    p {
        line-height: 170%;
    }
    @media (max-width: 1800px) {
        height: 25rem;
        width: 25rem;
    }
    @media (max-width: 688px) {
        height: 20rem;
        width: 20rem;
    }
`;

export const CardBack = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    height: 50vh;
    width: 400px;
`;
