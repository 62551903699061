import React, { useEffect, useState } from "react";

import {
    FirstDiv,
    InfoBottomText,
    InfoTopText,
    MainContainer,
    SplitLeft,
    SplitRight,
} from "./YourDataSection.styles";
import { Fade, Flip, Zoom } from "react-reveal";

import { Col, Row } from "antd";

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

import BrainFig from "../../../../../common/img/YourDataImage.png";

const YourDataSection = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const spanStyle = {
        whiteSpace: "nowrap", // Prevent word wrapping
        display: "inline-block",
        // margin: "0 10px", // Add margin to separate words
    };

    return (
        <FirstDiv>
            <MainContainer>
                <Row justify={"center"} align={"middle"} gutter={16}>
                    <Col xs={{ order: 2, span: 24 }} sm={{ order: 1, span: 6 }}>
                        <Fade left distance="75px" duration={1000}>
                            <SplitRight src={`${BrainFig}`} />
                        </Fade>
                    </Col>
                    <Col
                        xs={{ order: 1, span: 24 }}
                        sm={{ order: 2, span: 18 }}
                    >
                        <Flip left cascade duration={1800} opposite={true}>
                            <h1>
                                <span>Leading AI Customer Support Agents.</span>
                                <br />
                                <span>
                                    Driven solely by{" "}
                                    <span style={{ color: "lightpink" }}>
                                        your content.
                                    </span>
                                </span>{" "}
                            </h1>
                        </Flip>
                        <Fade up distance="100px" duration={1000}>
                            <SplitLeft>
                                <br />
                                <InfoBottomText
                                    style={{
                                        fontSize:
                                            "max(12px,min(.159375vw,14px))",
                                    }}
                                >
                                    Maximise your current resources with
                                    Galah AI. Our data-centric strategy
                                    guarantees personalised and dependable
                                    AI-assisted customer support encounters.
                                    Swift deployment and live data integration
                                    from your core resources ensure precise and
                                    uniform responses for your customers.
                                </InfoBottomText>
                            </SplitLeft>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </FirstDiv>
    );
};

export default YourDataSection;
