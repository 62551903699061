import React from "react";
import PricingHeader from "./PricingSections/PricingHeader/PricingHeader";
import PricingInfo from "./PricingSections/PricingInfo/PricingInfo";

const PricingPage = () => {
    return (
        <>
            <PricingHeader />
            <PricingInfo />
        </>
    );
};

export default PricingPage;
