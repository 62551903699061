const QAs = [
    {
        key: "1",
        label: "What is Galah AI?",
        children: (
            <p>
                Galah AI is a platform for creating custom AI chat agents for
                Australian businesses and organisations. Simply upload a
                document or insert a link from your website, and you'll have a
                chat agent capable of responding to queries related to its
                content.
            </p>
        ),
    },
    {
        key: "2",
        label: "What data sources can be uploaded?",
        children: (
            <p>
                Galah AI allows for various training sources including Word,
                Excel, and PDF documents as well as website links and YouTube videos.
            </p>
        ),
    },
    {
        key: "3",
        label: "Can I give my AI chat agent instructions?",
        children: (
            <p>
                Yes, you can revise the base prompt and give instructions to
                your chat agents on how to answer questions.
            </p>
        ),
    },
    {
        key: "4",
        label: "Where is my data stored?",
        children: (
            <p>
                Galah AI is hosted entirely in Australia. This way, we ensure
                that your data is never sent overseas, and it is never shared
                with any third parties (other than our reputable Cloud service
                providers).
            </p>
        ),
    },
    {
        key: "5",
        label: "How can I share my AI chat agent?",
        children: (
            <p>
                You can share your chat agent either via embedding it as an
                iframe or adding it as a chat bubble to your website.
            </p>
        ),
    },
];

export default QAs;
