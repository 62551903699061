import React from "react";
import {
    MainDiv,
    MainContainer,
    ColContainer,
    InfoBox,
} from "./ProductOfferingsSection.styles";

import { Row, Col, Divider } from "antd";
import { Fade } from "react-reveal";
import { AllStaffList, AuthorizedList, CustomerList } from "./POList";

const listItem = (title, body) => {
    return (
        <li>
            <strong style={{ color: "lightpink" }}>{title}</strong>
            {body}
        </li>
    );
};

const ProductOfferingSection = () => {
    return (
        <MainDiv>
            <MainContainer>
                <Row gutter={100}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Fade down distance={"100px"} duration={1000}>
                            <ColContainer>
                                <h1>Galah AI for Customers</h1>
                                <h2>Revolutionising Customer Interaction</h2>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
                <Row gutter={100}>
                    <Col sm={{ span: 24 }}>
                        <Fade up distance={"100px"} duration={1000}>
                            <ColContainer>
                                <InfoBox>
                                    <h3>
                                        In 2024, local organisations and
                                        businesses need to rethink their
                                        customer interaction strategies. While
                                        traditional phone calls have served as
                                        the cornerstone of customer service for
                                        years, the digital age demands a change.
                                        It's time to break free from
                                        conventional communication and embrace
                                        digital engagement through our
                                        intelligent AI chat agent.
                                    </h3>
                                    <br />
                                    {CustomerList.map((item, index) => {
                                        return listItem(item.title, item.body);
                                    })}
                                    <br />
                                    <h3>
                                        Don't wait – join the digital
                                        transformation and redefine the way you
                                        connect with your customers. Say goodbye
                                        to old practices and hello to the future
                                        of digital customer engagement with our
                                        intelligent AI chat agent.
                                    </h3>
                                </InfoBox>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
            <MainContainer>
                <Row gutter={100}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Fade down distance={"100px"} duration={1000}>
                            <ColContainer>
                                <h1>Galah AI for All Staff:</h1>
                                <h2>
                                    Enhancing Staff Productivity and Data
                                    Security
                                </h2>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
                <Row gutter={100}>
                    <Col sm={{ span: 24 }}>
                        <Fade up distance={"100px"} duration={1000}>
                            <ColContainer>
                                <InfoBox>
                                    <h3>
                                        In today's digital age, security is
                                        paramount for organisations. Standard
                                        chatbot services can pose significant
                                        security risks, potentially jeopardising
                                        sensitive data. That's why we offer
                                        Galah AI, a robust and secure AI chat
                                        agent solution hosted exclusively in
                                        Australia.
                                    </h3>
                                    <br />
                                    {AllStaffList.map((item, index) => {
                                        return listItem(item.title, item.body);
                                    })}
                                    <br />
                                    <h3>
                                        Don't compromise on security – switch to
                                        Galah AI and safeguard your business
                                        with confidence. Boost productivity and
                                        protect your data with Galah AI for All
                                        Staff.
                                    </h3>
                                </InfoBox>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
            <MainContainer>
                <Row gutter={100}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Fade down distance={"100px"} duration={1000}>
                            <ColContainer>
                                <h1>Galah AI for Authorised Staff:</h1>
                                <h2>
                                    Tailored AI-Powered Conversations with Your
                                    Organisational Documents and Database
                                </h2>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
                <Row gutter={100}>
                    <Col sm={{ span: 24 }}>
                        <Fade up distance={"100px"} duration={1000}>
                            <ColContainer>
                                <InfoBox>
                                    <h3>
                                        Galah AI for Authorised Staff
                                        revolutionises the way you interact with
                                        your organisation's data sources.
                                        Tailored exclusively for authorised
                                        personnel, our AI chat agent seamlessly
                                        integrates with your specific non-public
                                        data sources, aligning perfectly with
                                        your organisational context and needs.
                                    </h3>
                                    <br />
                                    {AuthorizedList.map((item, index) => {
                                        return listItem(item.title, item.body);
                                    })}
                                    <br />
                                    <h3>
                                        Join the new era of data accessibility
                                        and intelligence with Galah AI for
                                        Authorised Staff. Experience a
                                        revolutionary way to harness the
                                        potential of your data for faster, more
                                        informed decisions. Whether you prefer a
                                        Software as a Service (SAAS) or
                                        integration into your IT systems,
                                        Galah AI offers a flexible solution for
                                        your organisation.
                                    </h3>
                                </InfoBox>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </MainDiv>
    );
};

export default ProductOfferingSection;
