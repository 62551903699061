import styled from "styled-components";

import { Button } from "antd";
export const MainDiv = styled.div`
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#3b4f53, #2a1f29);

    h1 {
        font-size: max(38px, min(2.5125vw, 54px));
        background: -webkit-linear-gradient(#edbabf, #ba828e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    h3 {
        font-weight: 500;
        font-size: max(16px, min(0.859375vw, 16.5px));
        background: -webkit-linear-gradient(#edbabf, #ba828e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 1rem;
    }
`;

export const TextDiv = styled.div`
    width: 60vw;
    align-items: center;
    text-align: center;
    h3 {
        text-align: left;
    }
    @media (max-width: 600px) {
        width: 80vw;
    }
`;
