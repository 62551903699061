import React from "react";
import AboutUsHeader from "./AboutUsSections/AboutUsHeader/AboutUsHeader";
import AboutUsInfo from "./AboutUsSections/AboutUsInfo/AboutUsInfo";

const AboutUsPage = () => {
    return (
        <>
            <AboutUsHeader />
            <AboutUsInfo />
        </>
    );
};

export default AboutUsPage;
