import styled from "styled-components";

export const MainDiv = styled.div`
    width: 80%;
    height: 45vh;
    text-align: left;

    h1 {
        font-size: 28px;
    }

    border-radius: 10px;
    box-shadow: 0px 0px 10px #80808049;
    padding: 1rem;
    background: #ffffff7e;
`;
