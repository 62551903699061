import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled, { keyframes } from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    height: 30vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#3b4f53, #2a1f29);

    h1 {
        font-size: 3vw;
        color: #ffa5ae;
        @media (max-width: 1024px) {
            font-size: 5vw;
        }

        @media (max-width: 600px) {
            font-size: 8vw;
        }
    }

    p {
        font-size: 1.2vw;
        font-weight: 600;
        @media (max-width: 1024px) {
            font-size: 2vw;
        }

        @media (max-width: 600px) {
            font-size: 2.5vw;
        }
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const RoundButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffa5ae;
    border-radius: 100%;
    padding: 1rem;
    overflow: hidden;
    transition: ease all 0.25s;
    margin-left: 1rem;

    &:hover {
        padding: 1.2rem;
        color: white;
        background-color: #ffb9c0;
    }
    &:active {
        padding: 2rem;
        color: #ffffff;
        background-color: #fda3ac;
    }
    @media (max-width: 600px) {
        margin-top: 1rem;
        margin-bottom: -1.5rem;
    }
`;

export const ArrowRight = styled(ArrowRightOutlined)`
    font-size: 3vw;
    @media (max-width: 600px) {
        font-size: 6vw;
    }
`;
