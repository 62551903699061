import styled from "styled-components";

export const LoginMainDiv = styled.div`
    height: 82vh;
    width: 350px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
