// AppRoutes.js

const AppRoutes = {
    LANDING_PAGE: "/",
    PRODUCTS_PAGE: "/products",
    HUME_DEMO: "/demo/humecity-waste",
    DEAKIN_DEMO: "/demo/deakin-business",
    YARRA_DEMO: "/demo/yarra-city",
    VLA_DEMO: "/demo/victorian-legal-aid",
    SERVICES_PAGE: "/services",
    PRICING_PAGE: "/pricing",
    ABOUT_US_PAGE: "/about-us",
    FAQ_PAGE: "/frequently-asked-questions",
    NOT_FOUND: "*",
};

export default AppRoutes;
