import React from "react";
import { SOList } from "./SOList";
import { SplitContent } from "../../../../../components/SplitContent/SplitContent";
import { Divider } from "antd";
import { LogoImage, MainDiv, TextDiv } from "./ServicesOfferings.styles";
import Logo from "../../../../../common/img/LogoTransparent.png";

const ServicesOfferingsSection = () => {
    return (
        <>
            {SOList.map((item, index) => {
                const invert = index % 2 == 1 ? true : false;
                return (
                    <div key={index}>
                        <Divider />
                        <SplitContent
                            title={item.title}
                            body={item.body}
                            img={item.img}
                            invertOrder={invert}
                        />
                    </div>
                );
            })}
            <MainDiv>
                <TextDiv>
                    <h1>Transform with Confidence</h1>
                    <h3>
                        Are you ready to steer your business towards an
                        AI-powered future? Contact us for a free, no-obligation
                        consultation. Discover the boundless possibilities of AI
                        and why partnering with us today will be the best
                        investment for your business's tomorrow.
                    </h3>
                </TextDiv>
            </MainDiv>
        </>
    );
};

export default ServicesOfferingsSection;
