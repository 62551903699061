import { Link } from "react-router-dom";
import AppRoutes from "../../../common/AppRoutes";
import React from "react";
import { NotFoundDiv } from "./NotFound.styles";

const NotFound = () => {
    return (
        <NotFoundDiv>
            <h3>This page does not exist.</h3>
            <Link to={AppRoutes.LANDING_PAGE}>Back to homepage</Link>
        </NotFoundDiv>
    );
};

export default NotFound;
