import React from "react";
import requireAuth from "../requireAuth";
import { Space, Form, Input, Button, notification, message } from "antd";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../common/AppRoutes";

// Helper function to change password
// export async function changeThisPassword(values) {
export async function changeThisPassword() {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const body = {
        oldPassword: "mtgadmin",
        newPassword: "your_new_password",
        confirmPassword: "your_new_password",
    };

    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    try {
        const response = await fetch(
            backendUrl + "/api/change-password",
            requestOptions
        );

        if (response.ok) {
            message.success("Password successfully changed");
            return true; // Return true on success
        } else {
            const data = await response.json();
            notification.error({
                message: "Error",
                description: data.error || "Your transaction was unsuccessful",
            });
            return false; // Return false on failure
        }
    } catch (error) {
        notification.error({
            message: "Error",
            description: "An unexpected error occurred: " + error.message,
        });
        return false; // Return false on exception
    }
}

const changePassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        // Call the changePassword function
        const updateSuccess = await changeThisPassword(
            "/api/change-password/",
            values
        );
        if (updateSuccess) {
            navigate(AppRoutes.ACCOUNT); // Redirect to /home on successful change
        }
    };

    const onCancel = () => {
        navigate(AppRoutes.ACCOUNT); // Redirect to /home when Cancel is clicked
    };

    return (
        <div className="my-chatbots">
            <h1>Change Password</h1>
            <Form
                form={form}
                onFinish={onFinish}
                style={{ width: "350px", margin: "0 auto" }}
            >
                <Form.Item
                    name="oldPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input your current password!",
                        },
                    ]}
                >
                    <Input.Password
                        placeholder="Current Password"
                        autoComplete="off"
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password!",
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters!",
                        },
                    ]}
                >
                    <Input.Password placeholder="New Password" />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your new password!",
                        },
                    ]}
                >
                    <Input.Password placeholder="Confirm New Password" />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                    <Space>
                        <Button type="default" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Change Password
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default requireAuth(changePassword);
