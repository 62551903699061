import React from "react";
import { MainDiv, RoundButton, ArrowRight } from "./ProductsSection.styles";

import { ArrowRightOutlined } from "@ant-design/icons";
import AppRoutes from "../../../../../common/AppRoutes";
import { Link } from "react-router-dom";
import { Flip } from "react-reveal";

const ProductsSection = () => {
    return (
        <MainDiv>
            <Flip cascade right duration={1500}>
                <h1>Discover our Products</h1>
            </Flip>
            <Link
                style={{ textDecoration: "none", color: "white" }}
                to={AppRoutes.PRODUCTS_PAGE}
            >
                <RoundButton>
                    <ArrowRightOutlined />
                </RoundButton>
            </Link>
        </MainDiv>
    );
};

export default ProductsSection;
