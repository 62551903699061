import styled from "styled-components";

export const UseCasesSectionMainContainer = styled.div.attrs({
    className: "UseCasesSectionMainContainer",
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    margin: auto;
    @media (max-width: 768px) {
        max-width: 100vw;
        /* height: 100vh; */
        flex-direction: column;
    }
`;

export const SectionHeaderContainer = styled.div.attrs({
    className: "SectionHeaderContainer",
})`
    max-width: 80vw;
    @media (max-width: 768px) {
        max-width: 90vw;
    }
`;

export const UseCasesMainDiv = styled.div.attrs({
    className: "UseCasesMainDiv",
})`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    // Desktop
    height: 80vh;
    flex-direction: row;

    // Mobile
    @media (max-width: 768px) {
        margin-top: 2rem;
        height: auto;
        flex-direction: column;
    }
`;

export const UseCaseContainer = styled.div.attrs({
    className: "UseCaseContainer",
})`
    display: flex;
    flex-direction: row;
    border-radius: 30px;

    // Desktop
    width: 60%;
    height: 75%;

    // Mobile
    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
    }
`;

export const ConversationContainer = styled.div.attrs({
    className: "ConversationContainer",
})`
    overflow-y: auto;
    background-color: #818181;
    padding-bottom: 30px;

    h1 {
        font-size: 3vw;

        @media (max-width: 1024px) {
            font-size: 4vw;
        }

        @media (max-width: 768px) {
            font-size: 8vw;
        }
    }

    p {
        font-size: 1vw;
        font-weight: 500;
        @media (max-width: 1024px) {
            font-size: 2vw;
        }

        @media (max-width: 768px) {
            font-size: 3.5vw;
        }
    }

    // Desktop
    width: 100%;
    height: 100%;
    border-radius: 20px;

    // Mobile
    @media (max-width: 768px) {
        border-radius: 0px 0px 20px 20px;
        width: 100%;
        flex-direction: column;
        height: 50vh;
    }
`;

export const TabCard = styled.div.attrs({
    className: "TabCard",
})`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.$active ? "#818181" : "#5c5c5c")};
    color: ${(props) => (props.$active ? "white" : "#d2d2d2")};

    p {
        font-weight: ${(props) => (props.$active ? "700" : "500")};
    }
    &:hover {
        p {
            font-weight: 700;
            color: white;
        }
        cursor: pointer;
    }

    // Desktop
    flex-direction: column;
    justify-content: center;
    width: 120px;
    padding: ${(props) => (props.$active ? "0.7rem 1.25rem" : "0.5rem")};
    border-radius: 5px 0px 0px 5px;
    margin: 0px 0px 5px 0px;
    white-space: nowrap;

    // Mobile
    @media (max-width: 768px) {
        overflow-x: visible;
        flex-direction: row;
        justify-content: start;
        margin: 0px 0px 0px 0px;
        border-radius: 5px 5px 0px 0px;
        padding: ${(props) => (props.$active ? "1rem" : "0.25rem 0.25 rem")};
    }
`;

export const TabsContainer = styled.div.attrs({
    className: "TabsContainer",
})`
    display: flex;
    flex-direction: column;
    align-items: end;

    // Desktop
    flex-direction: column;
    justify-content: center;

    // Mobile
    @media (max-width: 768px) {
        flex-direction: row;
        overflow-x: auto;
        justify-content: start;
    }
`;

export const TextContainer = styled.div.attrs({
    className: "TextContainer",
})`
    // Desktop
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 40%;
    text-align: left;
    padding-right: 1rem;

    @media (max-width: 768px) {
        width: 90%;
        padding-right: 0;
        /* ul {
            display: none;
        } */
    }

    li {
        margin-bottom: 10px;
    }
    ul {
        padding-left: 10px;
    }
    p {
        font-size: max(16px, min(0.859375vw, 16.5px));
        font-weight: 600;
    }
`;
