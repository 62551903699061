import styled from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    margin-bottom: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 70vw;
    width: 70vw;
`;

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        text-align: left;
        font-size: max(38px, min(2.8125vw, 54px));
    }
    gap: 20px;
`;

export const LeftQuoteBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    gap: 10px;
    box-shadow: 0px 0px 10px #80808039;

    h1 {
        font-size: max(28px, min(2.03125vw, 39px));
        font-weight: 500;
    }
`;
