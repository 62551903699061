import React, { useState } from "react";
import {
    LandingDiv,
    LandingTextContainer,
    TopText,
    BottomText,
    RequestDemoButton,
} from "./HeroSection.styles";
import { Flip } from "react-reveal";
import EnquiryFormModal from "../../../../../components/EnquiryFormModal";

const HeroSection = () => {
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleFormSuccess = () => {
        setModalVisible(false); // Close the modal on form success
    };

    return (
        <LandingDiv>
            <LandingTextContainer>
                <Flip top cascade duration={1500}>
                    <div>
                        <TopText>Empowering Businesses</TopText>
                        <TopText>and Organisations with</TopText>
                        <TopText>
                            <span style={{ color: "lightpink" }}>Secure </span>
                            and{" "}
                            <span style={{ color: "lightpink" }}>
                                Customised{" "}
                            </span>
                        </TopText>
                        <TopText>AI Chat Agents</TopText>
                        <RequestDemoButton onClick={showModal}>
                            Request Demo
                        </RequestDemoButton>
                        <EnquiryFormModal
                            visible={modalVisible}
                            onCancel={handleCancel}
                            onSuccess={handleFormSuccess}
                        />
                    </div>
                </Flip>
            </LandingTextContainer>
        </LandingDiv>
    );
};

export default HeroSection;
