import React, { useEffect, useState } from "react";

import {
    FirstDiv,
    InfoBottomText,
    InfoTopText,
    MainContainer,
    SplitLeft,
    SplitRight,
} from "./AdaptableSection.styles";
import { Fade, Flip, Zoom } from "react-reveal";

import { Col, Row } from "antd";

import BrainFig from "../../../../../common/img/PrecisionImage.png";

const AdaptableSection = () => {
    return (
        <FirstDiv>
            <MainContainer>
                <Row justify={"center"} align={"middle"} gutter={32}>
                    <Col xs={{ order: 2, span: 24 }} sm={{ order: 2, span: 7 }}>
                        <Fade left distance="75px" duration={1000}>
                            <SplitRight src={`${BrainFig}`} />
                        </Fade>
                    </Col>
                    <Col
                        xs={{ order: 1, span: 24 }}
                        sm={{ order: 1, span: 17 }}
                    >
                        <Fade right distance="150px" duration={1000}>
                            <h1>
                                <span>Simplicity meets </span>{" "}
                                <span>
                                    <span style={{ color: "lightpink" }}>
                                        Precision.
                                    </span>
                                </span>
                            </h1>
                        </Fade>
                        <Fade up distance="100px" duration={1000}>
                            <SplitLeft>
                                <InfoBottomText
                                    style={{
                                        fontSize:
                                            "max(12px,min(.159375vw,14px))",
                                    }}
                                >
                                    Our AI Chat Agent has the unique ability to
                                    adapt its responses based on the complexity
                                    of the questions it encounters. Whether it's
                                    a straightforward inquiry or a more
                                    intricate issue, our AI Chat Agent ensures
                                    that each response is just right. For simple
                                    questions, you'll receive concise and
                                    straightforward answers, while for more
                                    complex inquiries, our AI Chat Agent
                                    provides the depth and precision you need.
                                    It's all about delivering the right level of
                                    information, no matter the complexity of the
                                    question.
                                </InfoBottomText>
                            </SplitLeft>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </FirstDiv>
    );
};

export default AdaptableSection;
