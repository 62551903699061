import React from "react";
import { Modal } from "antd";
import EnquiryForm from "./EnquiryForm";

const EnquiryFormModal = ({ visible, onCancel, onSuccess }) => {
    return (
        <Modal
            title="Request a customized demo"
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <EnquiryForm SubmitText={"Request Demo"} onSuccess={onSuccess} />
        </Modal>
    );
};

export default EnquiryFormModal;
