import React from "react";
import { Collapse } from "antd";
import QAs from "./QAs";

const Accordion = () => (
    <Collapse
        defaultActiveKey={["1"]}
        ghost
        items={QAs}
        accordion
        size="large"
    />
);
export default Accordion;
