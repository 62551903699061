import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import React from "react";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <ConfigProvider
        theme={{
            components: {
                Button: {
                    colorPrimary: "#e7697e",
                    colorPrimaryHover: "#e6798b",
                    colorPrimaryActive: "#e65a72",
                    primaryColor: "#eaeaea",
                },
                Tabs: {
                    // cardBg: "gray",
                    // cardPaddingLG: "12px 18px 8px",
                    cardGutter: "4",
                },
            },
        }}
    >
        <App />
    </ConfigProvider>
);
