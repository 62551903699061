import styled from "styled-components";
import LandingSplash from "../../components/img/LandingSplash.png";

// export const MainContainer = styled.div`
//     height: 100vh;
//     width: 100%;
//     background: url(${LandingSplash});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// `;

// export const LeftContainer = styled.div`
//     height: 30vh;
//     flex: 1;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;
// export const RightContainer = styled.div`
//     flex: 1;
//     padding-top: 10px;
//     display: flex;
//     height: 50vh;
//     flex-direction: column;
//     align-items: center;
// `;

export const MainDiv = styled.div`
    background: linear-gradient(
        to bottom,
        #e3e3e3 0%,
        rgb(159, 85, 106, 0.5) 100%
    );
    height: 100vh;
    /* padding: 100px 5%; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: white; */
`;

export const ChatSection = styled.div`
    height: 80%;
    width: 95%;
`;

export const IFrame = styled.iframe`
    border: 0;
    height: calc(100% - 10px);
    /* background: white; */
`;

export const ChatBox = styled.div`
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgb(159, 85, 106, 0.9);
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    background: rgba(253, 253, 253, 0.827);
`;
