import React from "react";
import {
    MainDiv,
    MainContainer,
    ColContainer,
    LeftQuoteBox,
} from "./AboutUsInfo.styles";

import { Row, Col } from "antd";
import { Fade } from "react-reveal";

const AboutUsInfo = () => {
    return (
        <MainDiv>
            <MainContainer>
                <Row gutter={100}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Fade left distance={"100px"} duration={1000}>
                            <ColContainer>
                                <h1>Who are we?</h1>
                                <LeftQuoteBox>
                                    <p>
                                        The galah, or the pink and grey
                                        cockatoo, is an Australian species of
                                        cockatoo. The galah is one of
                                        Australia's most abundant and widespread
                                        bird species. Galah AI, inspired by the
                                        cute and talkative galah, is like your
                                        customised ChatGPT (the famous
                                        conversational AI tool from OpenAI) that
                                        has been custom trained on your own
                                        data. With Galah AI, you can connect
                                        your company data sources and get a
                                        ChatGPT-like AI chat agent for your own
                                        data. You can embed this AI chat agent into
                                        your public website or private intranet.
                                        Visitors can chat with this agent, which
                                        generates its responses based on the
                                        materials you have provided.
                                        <br />
                                        <br />
                                        <span>
                                            Galah AI is a trademark of Avail
                                            Analytics Pty Ltd, a Melbourne-based
                                            technology company. Avail Analytics
                                            is an offshoot of Learn On Air Pty
                                            Ltd, an Australian software company
                                            providing innovative IT solutions
                                            since 2018 with offices in
                                            Melbourne. Galah AI Team has
                                            extensive expertise in data science,
                                            machine learning, and artificial
                                            intelligence with 20+ years of
                                            experience in delivering innovative
                                            solutions and technologies for the
                                            IT sector.
                                        </span>
                                    </p>
                                </LeftQuoteBox>
                            </ColContainer>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </MainDiv>
    );
};

export default AboutUsInfo;
