import React, { useEffect, useState } from "react";

import {
    FirstDiv,
    InfoBottomText,
    InfoTopText,
    MainContainer,
    SplitLeft,
    SplitRight,
} from "./MultilingualSection.styles";
import { Fade } from "react-reveal";
import CountUp from "react-countup";

import { Col, Row } from "antd";

import BrainFig from "../../../../../common/img/MultiLingualImage.png";

const MultilingualSection = () => {
    return (
        <FirstDiv>
            <MainContainer>
                <Row justify={"center"} align={"middle"} gutter={[32, 64]}>
                    <Col xs={{ order: 2, span: 24 }} sm={{ order: 1, span: 7 }}>
                        <Fade left distance="75px" duration={1000}>
                            <SplitRight src={`${BrainFig}`} />
                        </Fade>
                    </Col>
                    <Col
                        xs={{ order: 1, span: 24 }}
                        sm={{ order: 2, span: 17 }}
                    >
                        <Fade right distance="150px" duration={1000}>
                            <h1>
                                <span>Multilingual.</span>{" "}
                                <span>
                                    <span style={{ color: "lightpink" }}>
                                        <CountUp
                                            end={96}
                                            enableScrollSpy={true}
                                            scrollSpyOnce={true}
                                            duration={3}
                                            useEasing={true}
                                        />{" "}
                                    </span>
                                    languages supported.
                                </span>
                            </h1>
                        </Fade>
                        <Fade up distance="100px" duration={1000}>
                            <SplitLeft>
                                <InfoBottomText
                                    style={{
                                        fontSize:
                                            "max(12px,min(.159375vw,14px))",
                                    }}
                                >
                                    <br />
                                    Multilingual, Fair, and All-Embracing:
                                    Effective communication in various languages
                                    and inclusive service are vital, especially
                                    for seniors, individuals with disabilities,
                                    and those with limited literacy. Neglecting
                                    these groups leads to underserved
                                    communities. <strong>GalahAI</strong>{" "}
                                    addresses this by offering responses in
                                    multiple languages, utilising user-friendly
                                    interfaces, and employing straightforward
                                    language. This approach guarantees that
                                    services are accessible and inclusive for
                                    everyone, irrespective of their language
                                    proficiency, literacy, or technical skills.
                                </InfoBottomText>
                            </SplitLeft>
                        </Fade>
                    </Col>
                </Row>
            </MainContainer>
        </FirstDiv>
    );
};

export default MultilingualSection;
