import React from "react";
import ServicesHeader from "./ServicesSections/ServicesHeader/ServicesHeader";
import ServicesOfferingsSection from "./ServicesSections/ServicesOfferings/ServicesOfferings";

const ServicesPage = () => {
    return (
        <>
            <ServicesHeader />
            <ServicesOfferingsSection />
        </>
    );
};

export default ServicesPage;
