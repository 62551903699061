import styled from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    height: 60vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    h1 {
        text-align: left;
        font-size: 12vw;
        @media (max-width: 640px) {
            font-size: 18vw;
        }
    }

    h5 {
        text-align: left;
        font-size: 1rem;
    }
`;
