import React from "react";
import FAQHeader from "./FAQSections/FAQHeader/FAQHeader";
import FAQAccordion from "./FAQSections/FAQAccordion/FAQAccordion";

const FAQPage = () => {
    return (
        <>
            <FAQHeader />
            <FAQAccordion />
        </>
    );
};

export default FAQPage;
